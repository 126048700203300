import { BaseModel } from './Base.Models';


export class AutomationRuleModel extends BaseModel {
    public name: string;
    public type: string;
    public event?: string;
    public value?: string;
    public action: string;
    public followUpAction?: string;
    public status: boolean;
    public group?: Array<string>;
    public tags?: Array<string>;
    public user: Array<string>;
    public isBlocked?: boolean;
}