import { BaseModel } from "./Base.Models";

export class SimModel extends BaseModel {
    public constructor(params = {}) {
        super();
        Object.assign(this, params);
    }
    public name?: string;
    public barcode?: number | string;
    public msisdn?: number | string;
    public apn?: number | string;
    public iccid?: number | string;
    // public thingTags?: Array<TagModel>;
    public loding?: boolean;
}


export class SimDataTrafficModel extends BaseModel {
    public totalResults: number;
    public hits: Array<EventModel>;
}

export class EventModel extends BaseModel {
    public _source: {
        EsDataType: string,
        Severity: string,
        message: string,
        Timestamp: number | string,
        MessageType: string
    }
}

export class TagModel extends BaseModel {
    public name: string;
    public id: string;
    public customerId: string;
}