import { BaseModel } from "./Base.Models";
import { UserModel } from "./User.Models";

export class CompanyModel extends BaseModel {
    public name?: string;
    public phoneNumber?: number;
    public VAT?: number | string;
    public owner?: UserModel;
    public addedBy?: string;
    public invoicingName?: string;
    public invoicingPhone?: string;
    public invoicingEmail?: string;
    public invoicingAddress?: string;
    public hqAddress?: string;
    public hqDeliveryAddress?: string;
    public hqPostalCode?: string;
    public hqCity?: string;
    public hqCountry?: string;
    public plan?: string;
    public cost?: number;
    public network?: string;
}

export class BranchModel extends BaseModel {
    public name?: string;
    public address?: string;
    public postalCode?: string;
    public city?: string;
    public country?: string;
    public deliveryAddress?: string;
}

export class NetworkModel extends BaseModel {
    public Apn: object;
    public ApnId: string;
    public ApnName: string;
    public CustomerId: string;
    public DateCreated: string | number;
    public DateModified: string | number;
    public NetworkActive: boolean;
    public NetworkDescription: string;
    public NetworkId: string;
    public NetworkName: string;
    public Type: string;
    public VPN: any[];
}