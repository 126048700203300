import { BaseModel } from "./Base.Models";

export type ZoneType = 'BASE' | 'PLUS';

export class ZoneModel extends BaseModel {
    constructor(params = null) {
        super()
        Object.assign(this, params)
    }
    public name: string;
    public type: ZoneType;
}

export class PriceModel extends BaseModel {
    public name: string;
}

export class TadigZoneModel extends BaseModel {
    public TADIG: String | Object;
    public byterateFlag: Boolean;
    public csdRounding: String;
    public id: number;
    public moCSD: number;
    public moSMS: number;
    public moVoice: number;
    public mtCSD: number;
    public mtSMS: number;
    public mtVoice: number;
    public nbiotDownPacket: number;
    public nbiotUpPacket: number;
    public preferred: Boolean;
    public psData: number;
    public psDataRounding: String;
    public visitedNetworkIdentifier: String;
    public visitedNetworkName: String;
    public voiceRounding: String;
    public __v: number;
    public _id: String;
}