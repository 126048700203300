import { BaseModel } from "./Base.Models";

export class RechargeModel extends BaseModel {
    public amount: string;
    public method: string;
    public total_amount: string;
}

export class paymentModel extends BaseModel {
    public gatewayCharge: string;
    public transactionCharge: string;
    public amount: string;
    public currency: string;
    public email: string;
    public paymentIntentsKey: string;
    public clientSecret: string;
    public idempotencyKey: string;
    public status: string;
    public user: string;
    public createdAt: string;
    public invoiceUrl: string;
}


export class DataUsageModel extends BaseModel {
    public cdrType: String;
    public iccid: String;
    public IMSI: String;
    public MSISDN: String;
    public IMEI: String;
    public MNO: String;
    public mnoName: String;
    public MCC: Number;
    public MNC: Number;
    public thingName: String;
    public thingId: String;
    public thingsGroupId: String;
    public thingsGroupName: String;
    public timestamp: number;
    public usage: number;
    public APN: String;
}
