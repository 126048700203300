import { BaseModel } from "./Base.Models";




export class GroupModel extends BaseModel {
    public active: Number;
    public alarm: Number;
    public customerId: string;
    public data: Number;
    public dateModified: string;
    public networks: any;
    public pricePlans: any;
    public roamingPolicies: any;
    public SMS: number;
    public telephonyProfileId: string;
    public telephonyProfileName: string;
    public thingsGroupDescription: string;
    public thingsGroupId: string;
    public thingsGroupName: string;
    public voice: number;
    public total: number;
}